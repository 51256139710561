import _ from 'lodash';
import $ from 'jquery';
import I18n from 'common/i18n';
import getDefaultDomain from 'common/visualizations/helpers/getDefaultDomain';
import getLocalePrefix from 'common/js_utils/getLocalePrefix';
import { loaderLibrarySrc } from './paths';

const URL_CHARACTERS_LIMIT = { MIN: 0, MAX: 50 };

/**
 * Generates an embed code given a vif and some embed options.
 *
 * @param vif
 * @param options: Options relating to embed code generation
 *   - fallbackSourceLinkText: This text is displayed while we load the visualizations package
 *       or the package fails to load (networking issue or similar). Defaults to a generic
 *       "Explore the data".
 *   - sourceHref: href for the fallback source link. By default, links to the VIF's dataset page.
 *   - height, width: Override default dimensions. Any value jQuery understands, i.e. '200px'.
 */
export default function generateEmbedCode(vif, options) {
  // N.B.: The HTML generated by this function is immutable once embedded
  // into a customer's page (I suppose we could ask them nicely to re-embed).
  // We should be _really_ careful what we put in here.
  options = options || {};

  const domain = _.get(vif, 'series[0].dataSource.domain') || getDefaultDomain();
  const datasetUid = _.get(vif, 'series[0].dataSource.datasetUid');
  const defaultSourceHref = (domain && datasetUid) ? `https://${domain}${getLocalePrefix()}/d/${datasetUid}?referrer=embed` : null;
  const fallbackSourceLinkText = options.fallbackSourceLinkText || I18n.t('shared.visualizations.charts.embed.explore_data_link');
  const domainAttr = `data-socrata-domain="${domain}" `;
  const localeAttr = `data-locale="${I18n.locale}"`;
  const vizcanUid = _.get(options, 'vizcanUid');

  const scriptTag = `<script type="text/javascript" charset="UTF-8" ${localeAttr} ${domainAttr}src="${loaderLibrarySrc(domain)}"></script>`;

  // Some of these fields will resolve to null or undefined.
  // This is fine - jQuery will not set those particular
  // attributes, which is what we want.
  const attrs = {
    'class': 'socrata-visualization-embed',
    'data-embed-version': '1',
    'data-height': options.height,
    'data-socrata-domain': domain,
    'data-vizcan-uid': vizcanUid,
    'data-vif': JSON.stringify(vif),
    'data-width': options.width,
    href: _.get(options, 'sourceHref', defaultSourceHref),
    rel: 'external',
    target: '_blank'
  };

  const link = $('<a>', attrs).
    text(fallbackSourceLinkText)[0].
    outerHTML;

  return `${scriptTag}\n${link}`;
}

export function generateIframeEmbedCode(domain, viewId, viewName, options = {}) {
  const queryParams = $.param(_.pick(options, 'width', 'height'));
  const encodedViewName = _.isNil(viewName) ? '-' : convertToUrl(viewName);
  const iFrameSrc = `https://${domain}${getLocalePrefix()}/dataset/${encodedViewName}/${viewId}/embed?${queryParams}`;

  // Apply inline style to the iframe:
  // To get rid of the margin/padding set by the browser's "User Agent Stylesheet"
  // and make the iframe/viz take same height and width. Thereby also avoiding scrollbar.
  // The below styles have been taken from reset.css.
  return '<iframe' +
    ' allow="geolocation"' + // Enables the "find me" feature for maps
    ` src="${iFrameSrc}"` +
    ` width="${options.width}"` +
    ` height="${options.height}"` +
    ' style="border:0; padding: 0; margin: 0;">' +
    '</iframe>';
}


function convertToUrl(name) {
  const url = name.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9_\-]/g, '-').replace(/\-+/g, '-');

  if (url.length < 1) {
    return '-';
  }

  return url.slice(URL_CHARACTERS_LIMIT.MIN, URL_CHARACTERS_LIMIT.MAX);
}
