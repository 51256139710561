import React, { InputHTMLAttributes, useState } from 'react';
import classNames from 'classnames';
import { MAX_HEADER_TEXT_LENGTH } from './TableHierarchyGroupingHeader';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

export interface TableHierarchyGroupingHeaderInputProps extends InputHTMLAttributes<HTMLInputElement> {}

const TableHierarchyGroupingHeaderInput = React.forwardRef<
  HTMLInputElement,
  TableHierarchyGroupingHeaderInputProps
>((props, ref) => {
  const { onChange, value } = props;

  const [characterCount, setCharacterCount] = useState(`${value}`.length);

  const isValidInput = (): boolean => {
    return characterCount <= MAX_HEADER_TEXT_LENGTH;
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueLength: number = `${e.target.value}`.length;
    setCharacterCount(valueLength);

    if (onChange) {
      onChange(e);
    }
  };

  const titleInfoClasses = classNames({
    'hierarchy-header-title-info': true,
    error: !isValidInput()
  });

  const titleInputFieldClasses = classNames({
    'hierarchy-header-title-input-field': true,
    error: !isValidInput()
  });

  return (
    <>
      <input
        {...props}
        ref={ref}
        className={titleInputFieldClasses}
        value={value}
        onChange={handleOnChange}
      />

      <div className={titleInfoClasses}>
        <SocrataIcon name={IconName.Warning} />
        <div>
          {characterCount}/{MAX_HEADER_TEXT_LENGTH}
        </div>
      </div>
    </>
  );
});

export default TableHierarchyGroupingHeaderInput;
