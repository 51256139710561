// Visualization hydrator. Replaces embed codes with real visualizations.

import { cloneDeep, get, set } from 'lodash';
import $ from 'jquery';
import { views as visualizationViews, VisualizationRenderer } from 'common/visualizations';
import { EMBED_SIZES, DEFAULT_EMBED_SIZE_INDEX } from 'common/visualizations/views/SvgConstants';
import { MetadataProvider } from 'common/visualizations/dataProviders';

// We are using a centralized location to register Forge Icons for the following reason:
// Note: [Importing the desired icon(s) and defining them within the Forge icon registry] should be done as
// early in your application bootstrapping process as possible. Make sure all icons are defined, prior to
// the page rendering or else the component will not be able to find the icon data when it is instantiated.
// For this reason, icons are defined for the whole site in common/site_wide.jsx,
// however they need to be defined here for embeds.
import 'common/js_utils/forgeIconRegistry';
/**
 * This will import all Tyler Forge web components
 */
import { defineComponents } from '@tylertech/forge';

const { FlyoutRenderer } = visualizationViews;

const DEFAULT_WIDTH = '500px';
const DEFAULT_HEIGHT = '400px';

/**
 * Renders all unrendered embed codes.
 */
export default function hydrateEmbeds() {
  defineComponents();
  const $embeds = $('.socrata-visualization-embed:not(.rendered)');
  $embeds.each((i, element) => {
    try {
      hydrateEmbed(element);
    } catch (e) {
      // Log error and continue with remainder of embeds.
      logWarning('Error rendering visualization.', e);
    }
  });
}

/**
 * Replaces the given embed code with a visualization.
 */
export const hydrateEmbed = async (element) => {
  const embedVersion = element.getAttribute('data-embed-version');
  const vifAttribute = element.getAttribute('data-vif');

  if (!embedVersion) {
    logWarning('Embed tag must specify a data-embed-version attribute.');
  } else if (embedVersion !== '1') {
    logWarning(`Library too old to render v${embedVersion} embeds`);
  } else if (!vifAttribute) {
    logWarning('Embed tag must specify a data-vif attribute.');
  } else if ($(element).hasClass('rendered')) {
    // TODO: VIF updates would be relatively easy - just trigger SOCRATA_VISUALIZATION_RENDER_VIF
    // However, we should actually figure out how we want external devs to interface with this
    // library.
    logWarning('Embed already rendered, skipping rerender.');
  } else {
    let vif = null;
    try {
      vif = JSON.parse(vifAttribute);
      const vizcanUid = element.getAttribute('data-vizcan-uid');
      const socrataDomain = element.getAttribute('data-socrata-domain');
      // These must complete _before_ the visualization is rendered
      await Promise.all([
        setAttributionLink(vif, vizcanUid, socrataDomain),
        setDataSources(vif, socrataDomain)
      ]);
    } catch (e) {
      logWarning('Embed data-vif attribute is not valid JSON.', e);
      return;
    }

    // Possible improvement: Use default sizes if chart isn't getting space in the
    // layout (say, any dimension less that 20px).
    const width = element.getAttribute('data-width') || DEFAULT_WIDTH;
    const height = element.getAttribute('data-height') || DEFAULT_HEIGHT;
    const defaultClassName = get(EMBED_SIZES, [DEFAULT_EMBED_SIZE_INDEX, 'className']);
    const embedSizeOption = EMBED_SIZES.find((embedSize) => {
      return embedSize.width == width && embedSize.height == height;
    });
    const className = get(embedSizeOption, 'className', defaultClassName);

    // Create a container to properly namespace styles; for embeds
    // 'namespaced_embed_styles.scss' is used to avoid style clobbering
    const embedContainer = document.createElement('div');
    embedContainer.classList.add('socrata-embed-container');

    const target = $(
      '<div>',
      {
        // Be nice to devs and preserve some attrs.
        'class': element.getAttribute('class'),
        'id': element.getAttribute('id')
      }
    );
    target.width(width);
    target.height(height);
    target.addClass('rendered');
    target.addClass(className);
    target.data('vif', vif); // For debugging help.

    embedContainer.appendChild(target[0]);
    $(element).replaceWith(embedContainer);

    let visualizationRenderer;

    try {
      visualizationRenderer = new VisualizationRenderer(vif, target, {
        // Since tag-level Styleguide is not on the page, instruct flyouts to aggressively
        // apply their own text formatting.
        flyoutRenderer: new FlyoutRenderer({ inheritTextStyle: false }),
        displayFilterBar: true
      });

      $(target).on('SOCRATA_VISUALIZATION_TOGGLE_MAP_LAYER', (event) => {
        const { relativeIndex, visible } = event.originalEvent.detail;
        const newVif = cloneDeep(vif);

        set(newVif, `series[${relativeIndex}].visible`, visible);

        visualizationRenderer.update(newVif);

        vif = newVif;
      });

    } catch (e) {
      logWarning('Visualization failed to render', e);
      // TODO better error UX.
      $(target).replaceWith(element); // Put the original content back.
    }
  }
};

export const logWarning = (message, error) => {
  if (window.console) {
    const args = [ `Socrata Visualizations: ${message}` ];
    if (error) {
      args.push(error);
    }
    console.warn.apply(console, args);
  }
};

const getDataSourceDomain = async (datasetUid, domain) => {
  const metadataProvider = new MetadataProvider({
    datasetUid,
    ...(domain && { domain })
  }, true);
  return await metadataProvider.getDataSourceDomain();
};

const setAttributionLink = async (vif, vizcanUid, socrataDomain) => {
  if (!vizcanUid) {
    // older js embed codes may not have this, and if we don't have a uid,
    // we won't be able to guess the correct domain
    return;
  }

  const metadataProvider = new MetadataProvider({
    datasetUid: vizcanUid,
    ...(socrataDomain && { domain: socrataDomain })
  }, true);
  const metadataAndFederationStatus = await metadataProvider.getDatasetMetadataAndFederationStatus();
  const attributionDomain = await metadataProvider.getAttributionDomain(metadataAndFederationStatus);
  if (attributionDomain) {
    set(vif, 'origin.url', `https://${attributionDomain}/d/${vizcanUid}`);
    set(vif, 'origin.title', metadataAndFederationStatus.metadata.name);
  }
  return vif;
};

const setDataSources = async (vif, socrataDomain) => {
  if (!socrataDomain || !vif.series) {
    // If socrataDomain was not passed in by the embed code,
    // then we expect this to render from a socrata page
    // (in other words this is an iframe embed) and the visualization
    // library should be able to handle getting the datasource.
    return;
  }

  for (let i = 0; i < vif.series.length; i ++) {
    const series = vif.series[i];
    if (series.dataSource) {
      const { datasetUid, domain } = series.dataSource;
      if (!domain ) {
        // NOTE: socrataDomain was not _always_ included by js embed
        // codes, but the ones that do not include socrataDomain _do_ have
        // the dataSource domain set for every series.
        set(vif, `series[${i}].dataSource.domain`, await getDataSourceDomain(datasetUid, socrataDomain));
      }
    }
  }
};
