// Main entry point for embeds. This file is loaded (via script tag)
// by src/embed/loader.js.
import $ from 'jquery';
import isEmpty from 'lodash/isEmpty';
import generateEmbedCode from './embedCodeGenerator';
import allLocales from 'common/i18n/config/locales';
import I18n from 'common/i18n';
import { appToken, fetchJson } from 'common/http';

import './namespaced_embed_styles.scss';

I18n.translations = allLocales;

window.socrata = window.socrata || {};
window.socrata.visualizationEmbed = window.socrata.visualizationEmbed || {};

async function fetchFromDomain(path) {
  const embedRootElement = document.querySelector('[data-socrata-domain]');

  if (!embedRootElement) {
    throw new Error('Could not locate embed code, thus unable to complete fetch.');
  }

  const socrataDomain = embedRootElement.getAttribute('data-socrata-domain');
  return fetchJson(`https://${socrataDomain}/${path}`);
}

// Try to fetch the visualization app token from our source domain,
// unless we already have one present on the page.
// We don't bundle this in the webpack bundle because:
// 1. We'd prefer to configure the app token via apps-marathon config,
//    and this isn't available during the webpack build,
// 2. It will require additional build complexity.
//
// If the app token can't be obtained, the visualization will still
// render, albeit with no app token. This means that (in theory)
// it will be subject to API rate limiting and its calls will be
// counted as community API calls in metrics.
//
// We fetch the source domain by looking for our embed's root element.
// If there are multiple embeds on the page, choose the first one. Our
// visualizations have no support for specifying an app token
// per-visualization.

async function fetchWindowState() {
  return fetchFromDomain('component/visualization/v1/window_state.json');
}

// The loader should be smart enough to only load this script once.
// If that fails or someone pulls us in directly, this check prevents
// duplicate invocations of this script from conflicting.
if (!window.socrata.visualizationEmbed.mainScriptLoaded) {
  (async () => {

    try {
      window.socrata = {
        ...window.socrata,
        ...await fetchWindowState()
      };
    } catch (e) {
      console.error('Unable to get window state, will proceed without.');
      console.error(e);
    }


    window.socrata.visualizationEmbed.mainScriptLoaded = true;
    window.socrata.visualizationEmbed.generateEmbedCode = generateEmbedCode;

    // Hydrate on doc ready (we have no guarantee of being loaded async).
    $(() => {
      // Now that translations and feature flags are available, load the visualization code.
      //
      // If we use the "import" syntax, all the viz modules (imported indirectly via the hydrator)
      // will be loaded before this file begins to execute. This will cause viz to not have access
      // to the translations, which we set up in this file. The physical position of the "import"
      // statement does not affect the evaluation time. It takes effect before any statements are
      // executed in the file. In contrast, require() evaluates the module at call time, allowing
      // us an opportunity to set up translations and feature flags first.
      const { 'default': hydrateEmbeds } = require('./hydrator');

      hydrateEmbeds();

      // Only expose rendering code once everything is ready.
      // Otherwise, any outstanding loader might call into us
      // too early.
      window.socrata.visualizationEmbed.hydrateEmbeds = hydrateEmbeds;
    });
  })();
}
